import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/docs.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`This documentation covers VIP-180 functionality for denominating pool tokens. For Uniswap-specific functionality, see `}<Link to='/docs/v2/smart-contracts/pair' mdxType="Link">{`Pair`}</Link>{`.`}</p>
    <h1 {...{
      "id": "code",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#code",
        "aria-label": "code permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Code`}</h1>
    <p><a parentName="p" {...{
        "href": "https://github.com/Uniswap/uniswap-v2-core/blob/master/contracts/UniswapV2ERC20.sol"
      }}><inlineCode parentName="a">{`UniswapV2ERC20.sol`}</inlineCode></a></p>
    <h1 {...{
      "id": "events",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#events",
        "aria-label": "events permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Events`}</h1>
    <h2 {...{
      "id": "approval",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#approval",
        "aria-label": "approval permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Approval`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`event Approval(address indexed owner, address indexed spender, uint value);
`}</code></pre>
    <p>{`Emitted each time an approval occurs via `}<a parentName="p" {...{
        "href": "#approve"
      }}>{`approve`}</a>{` or `}<a parentName="p" {...{
        "href": "#permit"
      }}>{`permit`}</a>{`.`}</p>
    <h2 {...{
      "id": "transfer",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#transfer",
        "aria-label": "transfer permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Transfer`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`event Transfer(address indexed from, address indexed to, uint value);
`}</code></pre>
    <p>{`Emitted each time a transfer occurs via `}<a parentName="p" {...{
        "href": "#transfer-1"
      }}>{`transfer`}</a>{`, `}<a parentName="p" {...{
        "href": "#transferfrom"
      }}>{`transferFrom`}</a>{`, `}<Link to='/docs/v2/smart-contracts/pair/#mint-1' mdxType="Link">{`mint`}</Link>{`, or `}<Link to='/docs/v2/smart-contracts/pair/#burn-1' mdxType="Link">{`burn`}</Link>{`.`}</p>
    <h1 {...{
      "id": "read-only-functions",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#read-only-functions",
        "aria-label": "read only functions permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Read-Only Functions`}</h1>
    <h2 {...{
      "id": "name",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#name",
        "aria-label": "name permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`name`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function name() external pure returns (string memory);
`}</code></pre>
    <p>{`Returns `}<inlineCode parentName="p">{`Uniswap V2`}</inlineCode>{` for all pairs.`}</p>
    <h2 {...{
      "id": "symbol",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#symbol",
        "aria-label": "symbol permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`symbol`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function symbol() external pure returns (string memory);
`}</code></pre>
    <p>{`Returns `}<inlineCode parentName="p">{`UNI-V2`}</inlineCode>{` for all pairs.`}</p>
    <h2 {...{
      "id": "decimals",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#decimals",
        "aria-label": "decimals permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`decimals`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function decimals() external pure returns (uint8);
`}</code></pre>
    <p>{`Returns `}<inlineCode parentName="p">{`18`}</inlineCode>{` for all pairs.`}</p>
    <h2 {...{
      "id": "totalsupply",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#totalsupply",
        "aria-label": "totalsupply permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`totalSupply`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function totalSupply() external view returns (uint);
`}</code></pre>
    <p>{`Returns the total amount of pool tokens for a pair.`}</p>
    <h2 {...{
      "id": "balanceof",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#balanceof",
        "aria-label": "balanceof permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`balanceOf`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function balanceOf(address owner) external view returns (uint);
`}</code></pre>
    <p>{`Returns the amount of pool tokens owned by an address.`}</p>
    <h2 {...{
      "id": "allowance",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#allowance",
        "aria-label": "allowance permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`allowance`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function allowance(address owner, address spender) external view returns (uint);
`}</code></pre>
    <p>{`Returns the amount of liquidity tokens owned by an address that a spender is allowed to transfer via `}<a parentName="p" {...{
        "href": "#transferfrom"
      }}>{`transferFrom`}</a>{`.`}</p>
    <h2 {...{
      "id": "domain_separator",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#domain_separator",
        "aria-label": "domain_separator permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`DOMAIN_SEPARATOR`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function DOMAIN_SEPARATOR() external view returns (bytes32);
`}</code></pre>
    <p>{`Returns a domain separator for use in `}<a parentName="p" {...{
        "href": "#permit"
      }}>{`permit`}</a>{`.`}</p>
    <h2 {...{
      "id": "permit_typehash",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#permit_typehash",
        "aria-label": "permit_typehash permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`PERMIT_TYPEHASH`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function PERMIT_TYPEHASH() external view returns (bytes32);
`}</code></pre>
    <p>{`Returns a typehash for use in `}<a parentName="p" {...{
        "href": "#permit"
      }}>{`permit`}</a>{`.`}</p>
    <h2 {...{
      "id": "nonces",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#nonces",
        "aria-label": "nonces permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`nonces`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function nonces(address owner) external view returns (uint);
`}</code></pre>
    <p>{`Returns the current nonce for an address for use in `}<a parentName="p" {...{
        "href": "#permit"
      }}>{`permit`}</a>{`.`}</p>
    <h1 {...{
      "id": "state-changing-functions",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#state-changing-functions",
        "aria-label": "state changing functions permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`State-Changing Functions`}</h1>
    <h2 {...{
      "id": "approve",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#approve",
        "aria-label": "approve permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`approve`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function approve(address spender, uint value) external returns (bool);
`}</code></pre>
    <p>{`Lets `}<inlineCode parentName="p">{`msg.sender`}</inlineCode>{` set their allowance for a spender.`}</p>
    <ul>
      <li parentName="ul">{`Emits `}<a parentName="li" {...{
          "href": "#approval"
        }}>{`Approval`}</a>{`.`}</li>
    </ul>
    <h2 {...{
      "id": "transfer-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#transfer-1",
        "aria-label": "transfer 1 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`transfer`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function transfer(address to, uint value) external returns (bool);
`}</code></pre>
    <p>{`Lets `}<inlineCode parentName="p">{`msg.sender`}</inlineCode>{` send pool tokens to an address.`}</p>
    <ul>
      <li parentName="ul">{`Emits `}<a parentName="li" {...{
          "href": "#transfer"
        }}>{`Transfer`}</a>{`.`}</li>
    </ul>
    <h2 {...{
      "id": "transferfrom",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#transferfrom",
        "aria-label": "transferfrom permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`transferFrom`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function transferFrom(address from, address to, uint value) external returns (bool);
`}</code></pre>
    <p>{`Sends pool tokens from one address to another.`}</p>
    <ul>
      <li parentName="ul">{`Requires approval.`}</li>
      <li parentName="ul">{`Emits `}<a parentName="li" {...{
          "href": "#transfer"
        }}>{`Transfer`}</a>{`.`}</li>
    </ul>
    <h2 {...{
      "id": "permit",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#permit",
        "aria-label": "permit permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`permit`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`function permit(address owner, address spender, uint value, uint deadline, uint8 v, bytes32 r, bytes32 s) external;
`}</code></pre>
    <p>{`Sets the allowance for a spender where approval is granted via a signature.`}</p>
    <ul>
      <li parentName="ul">{`See `}<Link to='/docs/v2/smart-contract-integration/supporting-meta-transactions/' mdxType="Link">{`Using Permit`}</Link>{`.`}</li>
      <li parentName="ul">{`Emits `}<a parentName="li" {...{
          "href": "#approval"
        }}>{`Approval`}</a>{`.`}</li>
    </ul>
    <h1 {...{
      "id": "interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#interface",
        "aria-label": "interface permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Interface`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`import '@uniswap/v2-core/contracts/interfaces/IUniswapV2ERC20.sol';
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-solidity"
      }}>{`pragma solidity >=0.5.0;

interface IUniswapV2ERC20 {
  event Approval(address indexed owner, address indexed spender, uint value);
  event Transfer(address indexed from, address indexed to, uint value);

  function name() external pure returns (string memory);
  function symbol() external pure returns (string memory);
  function decimals() external pure returns (uint8);
  function totalSupply() external view returns (uint);
  function balanceOf(address owner) external view returns (uint);
  function allowance(address owner, address spender) external view returns (uint);

  function approve(address spender, uint value) external returns (bool);
  function transfer(address to, uint value) external returns (bool);
  function transferFrom(address from, address to, uint value) external returns (bool);

  function DOMAIN_SEPARATOR() external view returns (bytes32);
  function PERMIT_TYPEHASH() external pure returns (bytes32);
  function nonces(address owner) external view returns (uint);

  function permit(address owner, address spender, uint value, uint deadline, uint8 v, bytes32 r, bytes32 s) external;
}
`}</code></pre>
    <h1 {...{
      "id": "abi",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#abi",
        "aria-label": "abi permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ABI`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`import IUniswapV2ERC20 from '@uniswap/v2-core/build/IUniswapV2ERC20.json'
`}</code></pre>
    <p><a parentName="p" {...{
        "href": "https://unpkg.com/@uniswap/v2-core@1.0.0/build/IUniswapV2ERC20.json"
      }}>{`https://unpkg.com/@uniswap/v2-core@1.0.0/build/IUniswapV2ERC20.json`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      